import React, { useState, useContext } from 'react';
import { Context } from "../context/WalletContext";
import detectEthereumProvider from '@metamask/detect-provider'
import { ethers } from 'ethers';
import { Button } from "../components/Button";
import LoaderGlass from "../assets/svg/LoaderGlass";
import { useSphereOneProvider } from "../utils/SphereOneHooks";
import Popup from "../components/Popup";
import ember500 from '../assets/img/fuckOpenLoot/ember_package_500.png';
import ember1200 from '../assets/img/fuckOpenLoot/ember_package_1200.png';
import ember2400 from '../assets/img/fuckOpenLoot/ember_package_2400.png';
import ember6500 from '../assets/img/fuckOpenLoot/ember_package_6500.png';
import ember13500 from '../assets/img/fuckOpenLoot/ember_package_13500.png';
import ember70000 from '../assets/img/fuckOpenLoot/ember_package_70000.png';

const qorbiService = require('../context/QorbiService');

const TokenMarket = () => {
    const { qorbiUserData } = useSphereOneProvider();

    const [showLoader, setShowLoader] = useState([]);
    const [showPopup, setShowPopup] = useState(false);

    const items = [
        {
            id: 1,
            name: '500 Ember',
            price: 5,
            image: ember500,
            // stripe: 'https://buy.stripe.com/8wM8zF7DBbuM2VqaF4',
            // coinbase:'https://commerce.coinbase.com/checkout/c1f28039-d162-42ba-aa6f-8e9f83d26a24',
        },
        {
            id: 2,
            name: '1200 Ember',
            price: 10,
            image: ember1200,
            // stripe: 'https://buy.stripe.com/cN20396zxfL27bGfZp',
            // coinbase:'https://commerce.coinbase.com/checkout/a1631202-c1b6-4e05-bd82-35b743d9af53',
        },
        {
            id: 3,
            name: '2500 Ember',
            price: 20,
            image: ember2400,
            // stripe: 'https://buy.stripe.com/eVag279LJ42k53y5kM',
            // coinbase:'https://commerce.coinbase.com/checkout/da9ee63a-dffd-4ad6-aed1-81238e0d4e71',
        },
        {
            id: 4,
            name: '6500 Ember',
            price: 50,
            image: ember6500,
            // stripe: 'https://buy.stripe.com/28o2bh0b9dCUcw08wZ',
            // coinbase:'https://commerce.coinbase.com/checkout/1ef3cb25-f37b-4a61-85a9-25864f6161fc',
        },
        {
            id: 5,
            name: '13500 Ember',
            price: 100,
            image: ember13500,
            // stripe: 'https://buy.stripe.com/bIYcPVcXV42k53y8x0',
            // coinbase:'https://commerce.coinbase.com/checkout/198a7610-151b-493a-b1c0-f04415cf7481',
        },
        {
            id: 6,
            name: '70000 Ember',
            price: 500,
            image: ember70000,
            // stripe: 'https://buy.stripe.com/3cs3flbTR6aseE89B5',
            // coinbase:'https://commerce.coinbase.com/checkout/c3fa81d8-c3bf-47d1-acaa-be9edea47afa',
        },
    ];

    const purchaseUsingQorbi = async (asset) => {

        const tokenAddress = '0x07278800fFBbB715C826E9D780FFfE7ec7001127'
        const recipient = '0x5f3f479D94e7f3559bA451f9E79aF3DB6E8b43A0'
        const polygonChainId = '0x89';

        try {
            const providerMetamask = await detectEthereumProvider();
            if (providerMetamask) {
                if (!qorbiUserData?._id) {
                    window.location.href = '/profile';
                } else {
                    const accounts = await providerMetamask.request({
                        method: "eth_requestAccounts",
                    })
                    const provider = new ethers.providers.Web3Provider(window.ethereum);
                    const network = await provider.getNetwork();
                    if (network.chainId !== parseInt(polygonChainId, 16)) {
                        setShowLoader(["Incorrect Network", "Switch to Polygon"]);
                        alert("Please switch to the Polygon network.");
                        return;
                    }
                    const signer = provider.getSigner();
                    const erc20Abi = [
                        "function balanceOf(address owner) view returns (uint256)",
                        "function transfer(address to, uint256 amount) returns (bool)"
                    ];
                    const contract = new ethers.Contract(tokenAddress, erc20Abi, signer);
                    const balance = await contract.balanceOf(accounts[0]);
                    const assetData = await qorbiService.getQorbiPrice(asset, qorbiUserData._id);
                    if (balance.lt(ethers.utils.parseUnits(assetData.price, 18))) {
                        setShowLoader(["Insufficient Funds", "Please top up your wallet"]);
                        alert("You do not have enough Qorbi tokens.");
                        return;
                    }
                    const formattedPrice = parseFloat(assetData.price).toFixed(2);
                    setShowPopup({ price: formattedPrice });
                    setShowLoader(["Sending Funds", "Sending Funds"]);
                    const tx = await contract.transfer(recipient, ethers.utils.parseUnits(assetData.price, 18));
                    await tx.wait();
                    setShowLoader(["Minting Assets", " Wait a second..."]);
                    const minted = await qorbiService.mintQorbiMarket(accounts[0], qorbiUserData._id);
                    if (minted.status === 'success') {
                        window.location.href = '/my-assets';
                    }
                }
            }

        } catch (error) {
            setShowLoader(["Minting Failed", "Contact Support"]);
            console.log(error.message);
        }
    }

    return (
        <div className="elixir-logs-container">
            {showLoader.length > 0 &&
                <div className="loader-center">
                    <div style={{ fontSize: '24px', marginBottom: '10px' }}>
                        {showLoader[0]}
                    </div>
                    <LoaderGlass />
                    <div style={{ fontSize: '24px', marginBottom: '10px' }}>
                        {showLoader[1]}
                    </div>
                </div>
            }
            {showPopup && (
                <Popup
                    onClose={() => setShowPopup(false)}
                    title={`${showPopup.price}`}
                    content={
                        <>
                            <p>
                                $QORBI
                            </p>
                            <p>
                                By clicking Purchase below you will purchase this product. All Sales are Final
                            </p>
                            <Button transparent label="Purchase" onClick={() => setShowPopup(false)} />
                            <Button transparent label="Cancel" onClick={() => setShowPopup(false)} />
                        </>
                    }
                />
            )}
            <h1 className='home-replica-title'>Ember Token Purchase</h1>
            <div className="items-container-ember">
                {items.map((item) => (
                    <div key={item.id} className="item-card-ember">
                        <div className="card-header-ember">
                            <img src={item.image} alt={item.name} className="item-image-ember" />
                        </div>
                        <div className="card-body-ember">
                            <h2 className='h2-ember'>{item.name}</h2>
                            <p className='price-ember'>${item.price}</p>
                        </div>
                        <div className="card-footer-ember">
                            <button className='button-ember coinbase' onClick={() => purchaseUsingQorbi(item.name)}>
                                Buy with $QORBI (35% Off)
                            </button>
                            {/* <button
                                className='button-ember stripe'
                                onClick={() => window.open(`${item.stripe}?client_reference_id=${state.user.data}`)}
                            >
                                Buy using Credit Card
                            </button> */}
                        </div>
                    </div>
                ))}
            </div>
            <a
                className="cta-button"
                href="https://www.mexc.com/exchange/QORBI_USDT?utm_source=website&utm_medium=hero&utm_campaign=link"
                target="_blank"
                rel="noopener noreferrer"
            >
                Get $QORBI on MEXC Now! 🚀
            </a>
        </div>
    );
};

export default TokenMarket;