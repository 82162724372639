import { ImmutableXClient } from '@imtbl/imx-sdk';
import axios from 'axios';

const collections = {
    qorb: ['0x2d59c7b8d253b826cdba9389be397a68af88cf08', '0x2bCBb73C73d765A695F972BDd84222ee8ee55f2D'],
    qorx: ['0x6cc712a73cb45d212f594ef942385ef90141dac0', '0xcd05E79857c1c0Eb1EeAf1573a12C25D411E0757']
}

export const getAssetsFromCollections = async (user, collections, updateAssets, pageSize = 100) => {
    const client = await ImmutableXClient.build({
        publicApiUrl: "https://api.x.immutable.com/v1",
    });

    for (const collection of collections) {
        let cursor = '';
        while (true) {
            let allAssets = [];
            const response = await client.getAssets({
                user,
                collection,
                cursor,
                page_size: pageSize,
            });
            if (collection === "0x938b5c1850106ebc82d49af65a030e1d9083f2f5") {
                const processedBadges = await addBadgeAbleToMint(response.result);
                allAssets = allAssets.concat(processedBadges);
            } else {
                allAssets = allAssets.concat(response.result);
            }
            updateAssets(allAssets);
            if (response.cursor) {
                cursor = response.cursor;
            } else {
                break;
            }
        }
    }
};

export const assetOwner = async (user, collection) => {
    const client = await ImmutableXClient.build({
        publicApiUrl: "https://api.x.immutable.com/v1",
    });
    const response = await client.getAssets({
        user,
        collection: collections[collection][0],
    });
    if (response.result.length > 0) {
        return true;
    } else {
        const zkEvm = await axios.get(`https://immutable-mainnet.blockscout.com/api/v2/tokens/${collections[collection][1]}/instances?holder_address_hash=${user}`);
        if (zkEvm.data.items.length > 0) {
            return true
        };
    }
    return false;
};

const addBadgeAbleToMint = async (assets) => {
    // const baseUrl = "http://localhost:4001"
    const location = window.location
    const baseUrl = location.host === 'dev.qorbaby.com' ? "https://dev-api.qorbiworld.com" : "https://api.qorbiworld.com";
    const enhancedAssets = [];
    for (const asset of assets) {
        const { token_id } = asset;
        try {
            const response = await axios.get(`${baseUrl}/v1/check-badge-able-to-mint?tokenId=${token_id}`);
            if (response.data.isAble) {
                asset.isAble = true;
            } else {
                asset.isAble = false;
            }
        } catch (error) {
            console.error("Error while checking badge minting ability:", error.message);
            asset.isAble = false;
        }
        enhancedAssets.push(asset);
    }
    return enhancedAssets;
};