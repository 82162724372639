import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import qorbi_coin from '../assets/img/qorbi-coin.png';
import { ImmutableXClient } from "@imtbl/imx-sdk";
import { useSphereOneProvider } from '../utils/SphereOneHooks';
import { useNavigate } from 'react-router-dom';
import Popup from "../components/Popup";
import InputWallet from '../components/InputWallet';
import { Context } from "../context/WalletContext";
import { passportInstance } from "../context/PassportService";

const qorbiService = require('../context/QorbiService');

const QorbStaking = () => {

    const { logOut } = useContext(Context);

    const { devicedUsed, qorbiUserData } = useSphereOneProvider();

    const [qorbStaking, setQorbStaking] = useState(null);
    const [poolBalance, setPoolBalance] = useState(null);
    const [currentQorb, setCurrentQorb] = useState(null);
    const [qorbiBalance, setQorbiBalance] = useState(null);
    const [allQorbs, setAllQorbs] = useState(null);
    const [isOpenLoot, setIsOpenLoot] = useState(false);
    const [walletInput, setWalletInput] = useState(false);
    const [qorbId, setQorbId] = useState();
    const [showPopup, setShowPopup] = useState(false);

    const navigate = useNavigate();

    const urlParams = new URLSearchParams(window.location.search);
    const firstID = urlParams.get('qorb');
    const qorbWallet = urlParams.get('wallet');
    const qorbSource = urlParams.get('source');

    const logoutService = async () => {
        const currentSession = localStorage.getItem("userInfo")
        const infoFromString = JSON.parse(currentSession);
        localStorage.removeItem("userInfo")
        try {
            if (infoFromString.authType === "ImmutablePassport") {
                await passportInstance.logout();
            } else {
                navigate("/my-assets");
            }
        } catch (e) {
            console.error(e);
        }
    };

    const getPoolBalance = async () => {
        try {
            const balance = await qorbiService.emberPoolBalance()
            setPoolBalance(balance)
            const balanceData = await qorbiService.qorbiBalance();
            const balanceWithoutZeros = parseFloat(balanceData.balance);
            setQorbiBalance(balanceWithoutZeros);
        } catch (error) {
            if (error.message === 'Request failed with status code 401') {
                logOut();
                logoutService()
            }
        }

    }

    const getQorbDatas = async () => {
        getPoolBalance();
        const client = await ImmutableXClient.build({
            publicApiUrl: "https://api.x.immutable.com/v1",
        });
        if (qorbWallet !== undefined) {
            const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
            if (uuidPattern.test(qorbWallet)) {
                setIsOpenLoot(true);
                const openLootAssets = await qorbiService.getOpenLootAssets(qorbWallet);
                const qorbAssets = openLootAssets.filter(asset => asset.metadata.name === "Qorb");
                setQorbId(firstID.substring(0, 4));
                setAllQorbs(qorbAssets)
                const filteredQorb = qorbAssets.find(token => token.id === firstID);
                setCurrentQorb(filteredQorb);
                fetchData()
            } else {
                setIsOpenLoot(false);
                const qorbAssetsRequest = await client.getAssets({
                    user: qorbWallet,
                    collection: "0x2d59c7b8d253b826cdba9389be397a68af88cf08",
                });
                setAllQorbs(qorbAssetsRequest.result)
                const baseUrl = `https://immutable-mainnet.blockscout.com/api/v2/tokens/0x2bCBb73C73d765A695F972BDd84222ee8ee55f2D/instances?holder_address_hash=${qorbWallet}`;
                let unique_token = null;
                while (true) {
                    let url = unique_token ? (baseUrl + unique_token) : baseUrl;
                    const zkEvm = await axios.get(url);
                    const nftMetadataPromises = zkEvm.data.items
                        .map(async (token) => {
                            const tokenId = token.id;
                            try {
                                const metadataResponse = await fetch(
                                    `https://assets.qorbiworld.com/resource/qorb/${tokenId}`
                                );
                                if (!metadataResponse.ok) {
                                    throw new Error(
                                        `Error fetching metadata for token ${tokenId}: ${metadataResponse.statusText}`
                                    );
                                }
                                const metadata = await metadataResponse.json();
                                return {
                                    ...token,
                                    metadata,
                                };
                            } catch (error) {
                                console.error(
                                    `Error fetching metadata for token ${tokenId} in collection qorb:`,
                                    error
                                );
                                return null;
                            }
                        });

                    const nftsWithMetadata = (await Promise.all(nftMetadataPromises))
                        .filter((nft) => {
                            const name = nft?.metadata?.name;
                            return name !== "One Qorbi Burn" && name !== "One Tap Operative Mystery Box";
                        });
                    setAllQorbs((prevQorbs) => [...prevQorbs, ...nftsWithMetadata]);
                    if (zkEvm.data.items.length === 0 && qorbAssetsRequest.result.length === 0) {
                        navigate("/my-assets");
                    }
                    if (zkEvm.data.next_page_params) {
                        unique_token = `&unique_token=${zkEvm.data.next_page_params.unique_token}`;
                    } else {
                        break;
                    }
                }
                if (qorbSource === 'IMX') {
                    const filteredQorb = qorbAssetsRequest.result.find((token) => token.token_id === firstID);
                    setCurrentQorb(filteredQorb)
                } else {
                    const metadataResponse = await fetch(
                        `https://assets.qorbiworld.com/resource/qorb/${firstID}`
                    );
                    const metadata = await metadataResponse.json();
                    setCurrentQorb({ metadata });
                }
                fetchData()
            }
        }
    };

    const fetchData = async () => {
        // const baseUrl = "http://localhost:4001"
        const location = window.location
        const baseUrl = location.host === 'dev.qorbaby.com' ? "https://dev-api.qorbiworld.com" : "https://api.qorbiworld.com";
        try {
            const { data } = await axios.get(`${baseUrl}/stake/get-stake-qorbs/${qorbWallet}`);
            setQorbStaking(data);
        } catch (error) {
            console.error('Error fetching Qorb data:', error.message);
        }
    };

    const addToPool = async (target) => {
        const chainItem = qorbSource === 'IMX' ? 'immutablex' : 'zkevm'
        if (!qorbStaking?.isPoolQorb.includes(firstID) || (!qorbStaking.playingQorb && target === 'Playing')) {
            const flagQorb = await qorbiService.addQorbToPool({
                walletAddress: qorbWallet,
                QorXNftID: firstID,
                target,
                qorbName: currentQorb?.name || currentQorb?.metadata?.name || currentQorb?.extra?.attributes[0]?.value,
                qorbImage: currentQorb?.image_url || currentQorb?.extra?.imageUrl || currentQorb?.metadata?.image,
                chain: chainItem,
            })
            if (flagQorb.addedToQorbPool) {
                window.location.reload();
            }
        }
    }
    const handleWithdraw = async (address) => {
        setWalletInput(false)
        setShowPopup({ title: "Starting...", content: 'Withdrawing QORBI from QORB' });
        await qorbiService.withdrawQorb(qorbStaking.totalReward, address);
        // await qorbiService.withdrawQorb('1', address);
        window.location.reload();
    }

    useEffect(() => {
        getQorbDatas()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderMobileView = () => {
        return (
            <div style={{ padding: '20px', marginTop: 50, textAlign: 'center', fontFamily: 'SF UI Display, sans-serif', fontWeight: 'medium', fontSize: "18px" }}>
                {walletInput && <InputWallet onSuccess={handleWithdraw} />}
                {showPopup && (
                    <Popup
                        onClose={() => setShowPopup(false)}
                        title={showPopup.title}
                        content={
                            <>
                                <p>
                                    {showPopup.content}
                                </p>
                            </>
                        }
                    />
                )}
                {qorbStaking && (
                    <>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <img
                                src={currentQorb?.image_url || currentQorb?.extra?.imageUrl || currentQorb?.metadata?.image}
                                alt="QorX"
                                style={{
                                    width: 'auto',
                                    height: '40vh',
                                    border: '5px solid #2DD881',
                                    borderRadius: '15px',
                                    marginBottom: '20px',
                                }}
                            />
                            <div style={{ color: 'white', fontWeight: 'bold' }}>

                                <div style={{ color: 'white', fontWeight: 'bold', fontSize: '34px' }}>
                                    {`${currentQorb?.name || currentQorb?.metadata?.name || currentQorb?.extra?.attributes[0]?.value} #${isOpenLoot ? qorbId : firstID}`}
                                </div>
                                {Object.keys(qorbStaking?.groups).length > 0 &&
                                    <div style={{ color: '#A6A6A6', fontWeight: 'bold', fontSize: '24px' }}>
                                        Qorbs Owned: {allQorbs.length}
                                    </div>
                                }
                                <div style={{ color: '#A6A6A6', fontWeight: 'bold', fontSize: '24px' }}>
                                    Total Pool: {poolBalance} $EMBER
                                </div>
                                <br></br>
                                <div className='qorb-earning-container'>
                                    <img src={qorbi_coin} alt="Qorbi_Coin" />
                                    ‎ Qorbi Earned: {Math.floor(qorbStaking.totalReward * 10000) / 10000}
                                </div>
                                {!qorbStaking?.isPoolQorb.includes(firstID) && (!qorbStaking.playingQorb || qorbStaking.playingQorb === firstID) &&
                                    <button className={'btnTransparent'} onClick={() => addToPool('Playing')} style={{ width: '181.88px', height: '45px', marginBottom: 15 }}>
                                        {qorbStaking?.playingQorb === firstID ? 'Playing' : 'Play With Qorb'}
                                    </button>
                                }
                                {qorbStaking?.playingQorb !== firstID &&
                                    <button onClick={() => addToPool('Enter Pool')}
                                        className={`whitelist-qorbi-button ${qorbStaking?.isPoolQorb.includes(firstID) ? 'whitelist-qorbi-after' : ''}`}
                                    ></button>
                                }

                                <button className={'greenBtn'} onClick={() => setWalletInput(true)} style={{ width: '181.88px', height: '45px', marginTop: 15 }}>
                                    Withdraw Earning
                                </button>
                            </div>
                            <div className="pool-data-container">
                                <div className="pool-data-item">Total Earned In Pool: <p style={{ color: '#31E17F' }}>{qorbiBalance} $QORBI</p></div>
                                <div className="pool-data-item">Total Pool: <p style={{ color: '#31E17F' }}>{poolBalance} $EMBER</p></div>
                                <div className="pool-data-item">Next Distribution Date: Coming Soon</div>
                                <div className="pool-data-item">
                                    My Ember In Pool: <p style={{ color: '#31E17F' }}>{((qorbiUserData?.emberContribution?.[0]?.totalAmount || 0) * -1).toFixed(2)} $EMBER</p>
                                </div>
                                <div className="pool-data-item">
                                    Ember Contributor Bonus: <p style={{ color: '#31E17F' }}>{qorbiBalance * 1.25} $QORBI</p>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: '50px', marginRight: '20px', color: "white" }}>
                            <h3 style={{ fontWeight: 'bold', marginBottom: '15px', fontSize: "40px" }}>History of Pledging</h3>
                            <div style={{ width: '100%', marginTop: '10px', textAlign: 'start' }}>
                                <div style={{
                                    display: 'flex', flexDirection: 'row', padding: '10px', backgroundColor: '#181314', borderRadius: '25px',
                                }}>
                                    <div style={{ flex: 1 }}></div>
                                    <div style={{ flex: 4 }}>Date</div>
                                    <div style={{ flex: 2 }}>Token ID</div>
                                </div>
                                {qorbStaking?.groups[firstID]?.length > 0 && qorbStaking.groups[firstID].slice().map((row) => (
                                    <div key={row.tokenId} style={{ display: 'flex', flexDirection: 'row', padding: '10px' }}>
                                        <div style={{ flex: 1 }}></div>
                                        <div style={{ flex: 4 }}>{row.day}</div>
                                        <div style={{ flex: 2 }}>{row.tokenId.substring(0, 4)}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    };

    const renderComputerView = () => {
        return (
            <div style={{ display: 'flex', marginTop: 75, marginLeft: 75, fontFamily: 'SF UI Display, sans-serif', fontWeight: 'medium', fontSize: "18px" }}>
                <div style={{ flex: '2', padding: '20px' }}>
                    {walletInput && <InputWallet onSuccess={handleWithdraw} />}
                    {showPopup && (
                        <Popup
                            onClose={() => setShowPopup(false)}
                            title={showPopup.title}
                            content={
                                <>
                                    <p>
                                        {showPopup.content}
                                    </p>
                                </>
                            }
                        />
                    )}
                    {qorbStaking && (
                        <>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <img
                                    src={currentQorb?.image_url || currentQorb?.extra?.imageUrl || currentQorb?.metadata?.image}
                                    alt="QorX"
                                    style={{
                                        width: 'auto',
                                        height: '40vh',
                                        border: '5px solid #2DD881',
                                        borderRadius: '15px',
                                        marginRight: '20px',
                                    }}
                                />
                                <div>
                                    <div style={{ color: 'white', fontWeight: 'bold', fontSize: '34px' }}>
                                        {`${currentQorb?.name || currentQorb?.metadata?.name || currentQorb?.extra?.attributes[0]?.value} #${isOpenLoot ? qorbId : firstID}`}
                                    </div>
                                    {Object.keys(qorbStaking?.groups).length > 0 &&
                                        <div style={{ color: '#A6A6A6', fontWeight: 'bold', fontSize: '24px' }}>
                                            Qorbs Owned: {allQorbs.length}
                                        </div>
                                    }
                                    <br></br>
                                    <div className='qorb-earning-container'>
                                        <img src={qorbi_coin} alt="Qorbi_Coin" />
                                        ‎ Qorbi Earned: {Math.floor(qorbStaking.totalReward * 10000) / 10000}
                                    </div>
                                    {!qorbStaking?.isPoolQorb.includes(firstID) && (!qorbStaking.playingQorb || qorbStaking.playingQorb === firstID) &&
                                        <button className={'btnTransparent'} onClick={() => addToPool('Playing')} style={{ width: '181.88px', height: '45px', margin: '15px auto', display: 'block' }}>
                                            {qorbStaking?.playingQorb === firstID ? 'Playing' : 'Play With Qorb'}
                                        </button>
                                    }
                                    {qorbStaking?.playingQorb !== firstID &&
                                        <button onClick={() => addToPool('Enter Pool')}
                                            className={`whitelist-qorbi-button ${qorbStaking?.isPoolQorb.includes(firstID) ? 'whitelist-qorbi-after' : ''}`}
                                        ></button>
                                    }
                                    <button className={'greenBtn'} onClick={() => setWalletInput(true)} style={{ width: '181.88px', height: '45px', margin: '15px auto', display: 'block', }}>
                                        Withdraw Earning
                                    </button>
                                </div>
                            </div>
                            <div className="pool-data-container">
                                <div className="pool-data-item">Total Earned In Pool: <p style={{ color: '#31E17F' }}>{qorbiBalance} $QORBI</p></div>
                                <div className="pool-data-item">Total Pool: <p style={{ color: '#31E17F' }}>{poolBalance} $EMBER</p></div>
                                <div className="pool-data-item">Next Distribution Date: Coming Soon</div>
                                <div className="pool-data-item">
                                    My Ember In Pool: <p style={{ color: '#31E17F' }}>{((qorbiUserData?.emberContribution?.[0]?.totalAmount || 0) * -1).toFixed(2)} $EMBER</p>
                                </div>
                                <div className="pool-data-item">
                                    Ember Contributor Bonus: <p style={{ color: '#31E17F' }}>{qorbiBalance * 1.25} $QORBI</p>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                {allQorbs?.length > 0 && (
                    <div style={{ flex: '1', padding: '20px', marginTop: '25px', marginRight: '75px' }}>
                        <div style={{ color: 'white', marginTop: 25 }}>
                            <h3 style={{ fontWeight: 'bold', marginBottom: '15px', fontSize: "40px" }}>Pledging Qorbs</h3>
                            <div style={{ width: '100%', margin: '0 auto', marginTop: '10px', overflowX: 'hidden' }}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    background: "#1A1919",
                                    border: "1px solid #FFFFFF0D",
                                    borderRadius: "25px",
                                    opacity: "1",
                                    padding: "10px"
                                }}>
                                    <div style={{ flex: 4, paddingLeft: "30px" }}>Name</div>
                                    <div style={{ flex: 2 }}>ID</div>
                                </div>
                                {allQorbs.map((row) => (
                                    <div key={row.token_id} style={{ display: 'flex', flexDirection: 'row', padding: '10px' }}>
                                        <div style={{ flex: 4, paddingLeft: "30px" }}>{row?.name || row?.metadata?.name || row?.extra?.attributes[0]?.value}</div>
                                        <div style={{ flex: 2 }}>{row.token_id || row.id.substring(0, 4)}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div style={{ color: 'white', marginTop: 25 }}>
                            <h3 style={{ fontWeight: 'bold', marginBottom: '15px', fontSize: "40px" }}>History of Pledging</h3>
                            <div style={{ width: '100%', margin: '0 auto', marginTop: '10px', overflowX: 'hidden' }}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    background: "#1A1919",
                                    border: "1px solid #FFFFFF0D",
                                    borderRadius: "25px",
                                    opacity: "1",
                                    padding: "10px"
                                }}>
                                    <div style={{ flex: 4, paddingLeft: "30px" }}>Date</div>
                                    <div style={{ flex: 2 }}>Token ID</div>
                                </div>
                                {qorbStaking?.groups[firstID]?.length > 0 && qorbStaking.groups[firstID].slice().map((row) => (
                                    <div key={row.token_id} style={{ display: 'flex', flexDirection: 'row', padding: '10px' }}>
                                        <div style={{ flex: 4, paddingLeft: "30px" }}>{row.day}</div>
                                        <div style={{ flex: 2 }}>{row.tokenId.substring(0, 4)}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )
                }
            </div >
        );
    };

    return devicedUsed === 'mobile' ? renderMobileView() : renderComputerView();
};

export default QorbStaking;