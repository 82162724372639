import React, { useEffect, useState } from 'react';
import { checkout, config, orderbook } from '@imtbl/sdk';
import { passportInstance } from "../context/PassportService";

const baseConfig = new config.ImmutableConfiguration({
  environment: config.Environment.SANDBOX,
});

const orderbookClient = new orderbook.Orderbook({
  baseConfig: {
    environment: config.Environment.PRODUCTION,
    publishableKey: 'pk_imapik-_PHi3-wNvsLJhnzG4DEj',
  },
});

const checkoutSDK = new checkout.Checkout({ baseConfig });

const ZkMarketplace = () => {

  // Constants
  const contractAddress = "0x2bCBb73C73d765A695F972BDd84222ee8ee55f2D";

  // States
  const [widget, setWidget] = useState(undefined);
  const [provider, setProvider] = useState(null);
  const [walletAddress, setWalletAddress] = useState('');
  const [listings, setListings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Get Listings
  const listListings = async (contractAddress) => {
    const { result } = await orderbookClient.listListings({
      sellItemContractAddress: contractAddress,
      status: orderbook.OrderStatusName.ACTIVE,
      pageSize: 50,
    });
    return result;
  };

  const handleListListings = async () => {
    setIsLoading(true);
    try {
      const result = await listListings(contractAddress);
      setListings(result);
      console.log(result[3]);
    } catch (error) {
      console.error("Error listing assets:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Create Listing
  const prepareERC1155Listing = async () => {
    // build the sell item
    const sell = {
      contractAddress,
      tokenId: '699',
      amount: '1',
      type: "ERC1155",
    };
    // build the buy item
    const buy = {
      amount: '1000000000000000000',
      type: "NATIVE",
    }

    // build the prepare listing parameters
    const prepareListingParams = {
      makerAddress: walletAddress,
      buy,
      sell,
    };
    // invoke the orderbook SDK to prepare the listing
    return await orderbookClient.prepareListing(prepareListingParams);
  };

  const signAndSubmitApproval = async (provider, listing) => {
    // get your user's Web3 wallet, e.g. MetaMask, Passport, etc
    const signer = provider.getSigner();

    // If the user hasn't yet approved the Immutable Seaport contract to transfer assets from this
    // collection on their behalf they'll need to do so before they create an order
    const approvalActions = listing.actions.filter(
      (action) =>
        action.type === orderbook.ActionType.TRANSACTION,
    );

    for (const approvalAction of approvalActions) {
      const unsignedTx = await approvalAction.buildTransaction();
      const receipt = await signer.sendTransaction(unsignedTx);
      await receipt.wait();
    }

    return;
  };

  const signListing = async (provider, listing) => {
    const signer = provider.getSigner();
    console.log(1.1);
    const signableAction = listing.actions.find(
      (action) => action.type === orderbook.ActionType.SIGNABLE,
    );
    console.log(1.2);
    console.log(signableAction);
    const signature = await signer._signTypedData(
      signableAction.message.domain,
      signableAction.message.types,
      signableAction.message.value,
    );
    console.log(1.3);
    return signature;
  };
  const signListingTS = async (provider, listing) => {
    const signer = provider.getSigner();
    const signableAction = listing.actions.find(
      (action) =>
        action.type === orderbook.ActionType.SIGNABLE,
    );
    const signature = await signer._signTypedData(
      signableAction.message.domain,
      signableAction.message.types,
      signableAction.message.value,
    );

    return signature;
  };

  const createListing = async (client, preparedListing, orderSignature, makerEcosystemFee) => {
    const order = await client.createListing({
      orderComponents: preparedListing.orderComponents,
      orderHash: preparedListing.orderHash,
      orderSignature,
      // Optional maker marketplace fee
      makerFees: makerEcosystemFee ? [
        {
          recipientAddress: makerEcosystemFee.recipientAddress, // Replace address with marketplace address 0xF3F1e9D508D4a8f3620050E51aae8597b9dcaf48
          amount: makerEcosystemFee.amount,
        },
      ] : [],
    });
    console.log(order);
    return order.result.id;
  };

  const handleCreateListing = async () => {
    try {
      console.log(1);
      const preparedListing = await prepareERC1155Listing();
      console.log(2);
      await signAndSubmitApproval(provider, preparedListing);
      console.log(3);
      const signature = await signListing(provider, preparedListing);
      console.log(4);
      const orderId = await createListing(orderbookClient, preparedListing, signature);
      console.log(5);
      console.log('Order created with ID:', orderId);
    } catch (error) {
      console.error("Error creating listing:", error);
    }
  };

  // Use Effects
  useEffect(() => {
    if (passportInstance) {
      passportInstance.connectEvm();
    }
  }, []);

  useEffect(() => {
    (async () => {
      const factory = await checkoutSDK.widgets({
        config: {
          language: 'en',
          theme: checkout.WidgetTheme.DARK,
          WALLET: {
            showNetworkMenu: false,
            showDisconnectButton: true,
          },
        },
      });

      const checkoutWidget = factory.create(checkout.WidgetType.IMMUTABLE_COMMERCE);
      setWidget(checkoutWidget);
    })();
  }, []);

  useEffect(() => {
    if (!widget) return;

    widget.mount('mount-point', {
      flow: checkout.CommerceFlowType.CONNECT,
    });

    widget.addListener(
      checkout.CommerceEventType.SUCCESS,
      async (payload) => {
        const { type, data } = payload;

        if (type === checkout.CommerceSuccessEventType.CONNECT_SUCCESS) {
          setProvider(data.provider);
          // const accounts = await passportInstance.connectEvm().request({ method: 'eth_accounts' });
          const accounts = await data.provider.provider.request({ method: 'eth_accounts' });
          if (accounts && accounts.length > 0) {
            setWalletAddress(accounts[0]);
          }

          widget.unmount();
        }
      }
    );

    widget.addListener(
      checkout.CommerceEventType.FAILURE,
      (payload) => {
        console.log('Failed to connect', payload.data.reason);
      }
    );

    widget.addListener(checkout.CommerceEventType.CLOSE, () => {
      widget.unmount();
    });

    return () => {
      widget.removeListener(checkout.CommerceEventType.SUCCESS);
      widget.removeListener(checkout.CommerceEventType.FAILURE);
      widget.removeListener(checkout.CommerceEventType.CLOSE);
    };
  }, [widget]);

  return (
    <div
      style={{
        margin: "75px auto",
        maxWidth: "600px",
        padding: "20px",
        borderRadius: "12px",
        backgroundColor: "#1e1e2f",
        color: "#f9f9f9",
        textAlign: "center",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
      }}
    >
      <div id="mount-point" />
      {provider && walletAddress ? (
        <div>
          <h2 style={{ marginBottom: "20px" }}>Welcome to zkEVM Marketplace</h2>
          <p style={{ marginBottom: "30px" }}>
            Connected Wallet Address: <strong>{walletAddress}</strong>
          </p>
          {listings.length === 0 ? (
            <button
              onClick={handleListListings}
              style={{
                padding: "10px 20px",
                fontSize: "16px",
                borderRadius: "8px",
                backgroundColor: "#4CAF50",
                color: "#fff",
                border: "none",
                cursor: "pointer",
                transition: "background-color 0.3s ease",
              }}
              onMouseEnter={(e) => (e.target.style.backgroundColor = "#45a049")}
              onMouseLeave={(e) => (e.target.style.backgroundColor = "#4CAF50")}
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "List Assets"}
            </button>
          ) : (
            <div>
              <h3>Listed Assets:</h3>
              <ul style={{ textAlign: "left", margin: "20px 0" }}>
                {listings.map((item, index) => (
                  <li key={item.id}>
                    <strong>Token ID:</strong> {item.sell[0]?.tokenId || "N/A"}<br />
                    <strong>Contract Address:</strong> {item.sell[0]?.contractAddress}<br />
                    <strong>Price:</strong> {item.buy[0]?.amount || "N/A"}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {/* New Button to Create Listing */}
          <button
            onClick={handleCreateListing}
            style={{
              padding: "10px 20px",
              fontSize: "16px",
              borderRadius: "8px",
              backgroundColor: "#007BFF",
              color: "#fff",
              border: "none",
              cursor: "pointer",
              transition: "background-color 0.3s ease",
              marginTop: "20px",
            }}
            onMouseEnter={(e) => (e.target.style.backgroundColor = "#0069d9")}
            onMouseLeave={(e) => (e.target.style.backgroundColor = "#007BFF")}
          >
            Create Listing
          </button>
        </div>
      ) : (
        <p>Please connect your wallet to use the marketplace.</p>
      )}
    </div>
  );
};

export default ZkMarketplace;
