import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import ConnectWalletToBuy from "../components/ConnectWalletToBuy";
import { Toast } from "../utils/Global";
import { Context } from "../context/WalletContext";
import { useSphereOneProvider } from "../utils/SphereOneHooks";

const qorbiServices = require('../context/QorbiService');

const JoinPool = () => {

    const { qorbiUserData } = useSphereOneProvider();

    const { state } = useContext(Context);

    let navigate = useNavigate();

    const options = [
        { value: "none", label: "None" },
        { value: "MANA", label: "MANA" },
        { value: "GFB", label: "GFB" },
        { value: "FUGG", label: "FUGG" },
        { value: "OLAGG", label: "OLAGG" },
        { value: "FAM", label: "FAM" },
        { value: "ARUELPLAYS", label: "ARUELPLAYS" },
        { value: "GOATED", label: "GOATED" },
        { value: "EGG", label: "EGG" },
        { value: "PLAYNXG", label: "PLAYNXG" },
        { value: "KRAKENGAMING", label: "KRAKENGAMING" },
        // { value: "CoolQorJ", label: "CoolQorJ" },
        // { value: "QorCreator", label: "QorCreator" },
        // { value: "Posho", label: "Posho" },
    ];

    const [selectedValue, setSelectedValue] = useState(options[0]?.value);
    const [showConnectMessage, setShowConnectMessage] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);

    const images = [
        "https://digital.qorbiworld.com/ember.jpg",
        "https://digital.qorbiworld.com/onetapbanner.jpg",
        "https://digital.qorbiworld.com/qorb_pools.jpg",
    ];

    const joinPlayingPool = async () => {
        if (state.user !== null) {
            if (qorbiUserData.playingPool) {
                Toast.set({
                    show: true,
                    desc: `You are already part of the Pool, refered by: ${qorbiUserData.referralPool}`,
                    type: 'failed',
                });
            } else {
                await qorbiServices.joinPool(selectedValue);
                Toast.set({
                    show: true,
                    desc: `You are now part of the Pool, refered by: ${selectedValue}`,
                    type: 'success',
                });
                setTimeout(() => {
                    navigate(`/profile`)
                }, 3500);
            }
        } else {
            setShowConnectMessage(true)
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImage((prev) => (prev + 1) % images.length);
        }, 3000);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="container-pool">
            {showConnectMessage && <ConnectWalletToBuy></ConnectWalletToBuy>}
            <div className="left-pool">
                <div className='col-12'>
                    <h1 className="home-replica-title">Earn Rewards Playing for the QORBI Pledging Pool</h1>
                    <h2 className="home-replica-subtitle">Turn your FPS skills into real rewards</h2>
                    <h3 className="section-title" style={{ textAlign: "center" }}>Play One Tap FPS, rank up, and earn QORBI tokens by representing pledged QORBs.</h3>
                </div>

                <section className="how-it-works">
                    <h3 className="section-title">How It Works</h3>
                    <p className="qorb-pool-text">
                        Step into the Action—It’s Easy!
                        <ol>
                            <li>Sign Up below: Enroll as a pool player</li>
                            <li>Play on Behalf of a QORB: Represent pledged QORBs and earn rewards every time you play.</li>
                            <li>Reach Gameplay Limits: Complete 50 matches to unlock your earnings.</li>
                            <li>Accumulate Rewards: Keep playing to grow your QORBI balance.</li>
                        </ol>
                    </p>
                </section>
                <section className="why-join">
                    <h3 className="section-title">Why Join the QORBI Pool as a Player?</h3>
                    <ul className="benefits-list">
                        <li>Start Earning with Zero Investment: No need to own a QORB to participate.</li>
                        <li>Turn Gameplay Into Income: Earn QORBI tokens for every match played.</li>
                        <li>Join a Thriving FPS Community: Compete alongside the best and climb the leaderboards.</li>
                        <li>Unlock Special Bonuses: Get access to exclusive events, rewards, and challenges.</li>
                    </ul>
                    <div className="dropdown-container-pool">
                        {options.length > 0 &&
                            <select
                                className="dropdown-pool"
                                value={selectedValue}
                                onChange={(e) => setSelectedValue(e.target.value)}
                            >
                                {options.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        }
                        <button className="greenBtn" onClick={joinPlayingPool}>Enroll</button>
                    </div>
                </section>
                <section className="faq">
                    <h3 className="section-title">FAQ</h3>
                    <div className="faq-item">
                        <strong>Do I need to own a QORB to join?</strong>
                        <p>No. You’ll play on behalf of QORBs pledged by owners in the pool.</p>
                    </div>
                    <div className="faq-item">
                        <strong>How much can I earn?</strong>
                        <p>Earnings are based on the number of matches played and your performance.</p>
                    </div>
                    <div className="faq-item">
                        <strong>Can I withdraw my rewards immediately?</strong>
                        <p>Rewards unlock after completing the gameplay limit of 50 matches.</p>
                    </div>
                    <div className="faq-item">
                        <strong>What if I stop playing?</strong>
                        <p>If you don’t meet gameplay activity requirements, your QORB allocation may be reassigned to another player.</p>
                    </div>
                </section>
            </div>
            <div className="right-pool">
                <div className="carousel-pool">
                    <img src={images[currentImage]} alt="carousel" className="carousel-image-pool" />
                    <div className="carousel-text-pool">
                        <h2 className="carousel-title-pool">200 Free Ember</h2>
                        <p className="carousel-description-pool">Get this exclusive limited offer!</p>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default JoinPool;
